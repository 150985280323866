<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div v-if="this.$route.query.page == '1'" class="title-txt">공급기업 지정 프로젝트<br/>
						<p><span class="prj_ct">프로젝트 상세페이지</span></p>
					</div>
					<div v-else class="title-txt">일반 프로젝트<br/>
						<p><span class="prj_ct">프로젝트 상세페이지</span></p>
					</div>
				</div>
				<div class="myprj_tab"><img src="/images/myproject_tab.png" alt="마이프로젝트"/></div>
				<div class="contents-body demand tab">

					<ProjectInfo v-model="projectNm" :projectSeq="projectSeq" type="3" viewId="PRJ303M01" @goList="$router.go(-1)"/>

				</div>
			</div>
		</div>
	</div>

</template>

<script>

import ProjectInfo from '@/components/highpro/ProjectInfo.vue';

export default {

	components : {
		ProjectInfo
	},

	data() {
		return {
			projectSeq : 0,
			projectNm : ''
		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		if(this.$route.query.projectSeq != undefined && this.$route.query.projectSeq != '') {
			this.projectSeq = Number(this.$route.query.projectSeq);
		}		

	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

	},
};
</script>
